<div class="dds-modal-overlay" aria-label="Modal with content"  (click)="close()"></div>

<div ddsResize [class.dds-modal_lg]="isLarge" [class.dds-modal_inverse]="isInverse" class="dds-modal"
	[ngStyle]='{ width : customWidth, height : customHeight }'>
	<div class="dds-modal__header">
		@if (!!title) {
			<span class="dds-modal__title">{{ title }}</span>
		}
		<dds-button size="sm" icon="dds-icon_close" kind="silent" theme="dark" ariaLabel="Close modal"
			class="dds-modal__close" [isInverse]="isInverse" (clicked)="close()"></dds-button>
	</div>

	<div class="dds-modal__body">
		<ng-container #content></ng-container>
	</div>

	@if (type === 'alert' || type === 'confirm') {
		<div class="dds-modal__footer">
			<div class="dds-modal__footer-content dds-modal__footer-content_left">
			</div>
			<div class="dds-modal__footer-content dds-modal__footer-content_right">
				@if (type === 'confirm') {
					<dds-button class="dds-modal__footer-item" kind="secondaryLoud" [isInverse]="isInverse" (clicked)="cancel()" (keyboardClicked)="cancel()" ariaLabel="Cancel">Cancel</dds-button>
				}
				<dds-button class="dds-modal__footer-item" (clicked)="accept()" (keyboardClicked)="accept()" ariaLabel="Ok">Ok</dds-button>
			</div>
		</div>
	}
</div>
