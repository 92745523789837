import { Component, inject, ViewChild } from '@angular/core';
import { ButtonComponent } from '@usitsdasdesign/dds-ng/button';
import { Column } from '@usitsdasdesign/dds-ng/shared';
import { TableComponent, TableLoadEvent } from "../../shared/components/table/table.component";
import { User } from '../../shared/model/user';
import { UserService } from '../../shared/services/user.service';
import { firstValueFrom, take, takeUntil } from 'rxjs';
import { Accessible, PaginatedData } from '../../shared/model/model';
import { SearchComponent } from '@usitsdasdesign/dds-ng/search';
import { CommonModule } from '@angular/common';
import { PopupService } from '../../shared/services/popup.service';
import { UserFormComponent } from './user-form/user-form.component';
import { ModifyUserComponent } from './modify-modal/modify-modal.component';
import { TableEmptyComponent } from '../../shared/components/table-empty/table-empty.component';

@Component({
	selector: 'app-user-management',
	standalone: true,
	imports: [CommonModule, ButtonComponent, SearchComponent, TableComponent, TableEmptyComponent],
	templateUrl: './user-management.component.html',
	styleUrl: './user-management.component.sass'
})
export class UserManagementComponent {
	@ViewChild(TableComponent) private table!: TableComponent;
  private popups: PopupService = inject(PopupService);
	private userService: UserService = inject(UserService);
	private query: string = '';

	public users: (User & Accessible)[] = [];
	public totalSize: number = 0;
	public isLoading: boolean = false;

	public columns: Column[] = [
    { name: 'first_name', header: 'First Name', dataType: 'string', sortable: true, width: '150px' },
    { name: 'last_name', header: 'Last Name', dataType: 'string', sortable: true, width: '150px' },
    { name: 'email', header: 'Email', dataType: 'string', sortable: true, width: '250px' },
    { name: 'role_description', header: 'Privilege', dataType: 'string', sortable: true, width: '150px' },
    { name: 'processed_by_name', header: 'Processed by', dataType: 'string', sortable: true, width: '150px' },
    { name: 'processed_date', header: 'Start Date', dataType: 'date', sortable: true, width: '120px' },
    { name: 'action', header: 'Action', dataType: 'button', sortable: false, width: '200px' }
	];

	public async getUsers({ page, pageSize, sortingState }: TableLoadEvent) {
		this.isLoading = true;
		try {
			const { data, totalResults } = await new Promise<PaginatedData<User>>(resolve =>
				this.userService.getUsers(page, pageSize, sortingState, this.query)
				.pipe(take(1), takeUntil(this.table.load))
				.subscribe(resolve)
			);
			this.users = data.map(user => new User(user));
			this.totalSize = totalResults;
		} finally {
			this.isLoading = false;
			this.table.scrollTop();
		}
	}

	public searchUsers(search: string) {
		if (search.length > 0 && search.length < 3 || search === this.query)
			return;

		this.query = search;
		this.table.reload({ page: 1 });
	}

	async newUser() {
    if (await firstValueFrom(this.popups.drawer(UserFormComponent).onClosed))
			this.table.reload({ page:1 });
	}

	async modifyUser(user: User) {
		let modifyUserModal = this.popups.dialog(ModifyUserComponent, { title: 'Modify User Privileges', data: { user }, customWidth: '700px' });
		let result = await firstValueFrom(modifyUserModal.onClosed);
		if (result)
			this.table.reload({ page:1 });
	}
}
