import { ApplicationRef, EventEmitter, inject, Injectable, Type, ViewContainerRef } from '@angular/core';
import { DrawerComponent, DrawerOptions } from '../components/drawer/drawer.component';
import { ModalService } from '@usitsdasdesign/dds-ng/modal';
import { ModalComponent } from '../components/modal/modal.component';
import { Accessible } from '../model/model';
import { SpinnerComponent } from '../components/spinner/spinner.component';
import { ModalCommentButtons, ModalCommentComponent } from '../components/modal-comment/modal-comment.component';
import { ToastService } from '@usitsdasdesign/dds-ng/toast';

export interface PopupComponent { onClosed?: EventEmitter<any>; content?: Type<unknown> | string; }

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  private modalService: ModalService = inject(ModalService);
  private toastService: ToastService = inject(ToastService);
  private viewRef?: ViewContainerRef;

  constructor(appRef: ApplicationRef) {
    this.viewRef = appRef.components[0]?.instance.viewRef;
  }

  public get modals() {
    return this.modalService;
  }

  spinner(): SpinnerComponent {
    if (!this.viewRef) return this.spinnerComponent();

    const componentRef = this.viewRef.createComponent(SpinnerComponent);
    componentRef.instance.onStop.subscribe(() => componentRef.destroy());
    return componentRef.instance;
  }

  drawer(content: Type<unknown>, options: DrawerOptions = {}): DrawerComponent {
    if (!this.viewRef) return this.drawerComponent(content, options);

    const { data, ...instanceOptions } = options;

    const componentRef = this.viewRef.createComponent(DrawerComponent);
    componentRef.instance.content = content;
    componentRef.instance.options = { closeOnEsc: true, closeOnOutsideClick: true, ...instanceOptions };
    componentRef.instance.data = data;

    componentRef.instance.onClosed.subscribe(() => componentRef.destroy());
    return componentRef.instance;
  }

  toast(options: { title?: string, message?: string, isError?: boolean }) {
    const { title, message, isError } = { isError: false, ...options };
    this.toastService.createToast({ title, message, position: 'top-center', lifeTime: 3000, isError, isCloseIcon: true });
  }

  dialog(content: Type<unknown> | string, options?: { title?: string, isLarge?: boolean, isInverse?: boolean, data?: Accessible, customWidth?: string, customHeight?: string }): ModalComponent {
    return this.modal(content, { type: 'dialog', ...options });
  }

  confirm(content: Type<unknown> | string, options?: { title?: string, isLarge?: boolean, isInverse?: boolean, data?: Accessible }): ModalComponent {
    return this.modal(content, { type: 'confirm', ...options });
  }

  alert(content: Type<unknown> | string, options?: { title?: string, isLarge?: boolean, isInverse?: boolean, data?: Accessible }): ModalComponent {
    return this.modal(content, { type: 'alert', ...options });
  }

  confirmComment(options?: { title?: string, isLarge?: boolean, isInverse?: boolean, message?: string, placeholder?: string, buttons?: ModalCommentButtons, isOptional?: boolean }): ModalComponent {
    const { message, placeholder, buttons, isOptional, ...modalOptions } = options || {};
    return this.modal(ModalCommentComponent, { type: 'dialog', ...modalOptions, data: { message, placeholder, buttons, isOptional } });
  }

  private modal(content: Type<unknown> | string, options?: { title?: string; isLarge?: boolean, isInverse?: boolean, type: 'dialog' | 'confirm' | 'alert', data?: Accessible }): ModalComponent {
    if (!this.viewRef) return this.modalComponent(content, options);

    const modalRef = this.modalService.open(ModalComponent, { content, ...options }, true);
    modalRef.onClosed().subscribe((data) => modalRef.componentInstance.onClosed.emit(data));
    return modalRef.componentInstance;
  }

  // fallbacks
  private spinnerComponent(): SpinnerComponent {
    const onStop = new EventEmitter();
    const stop = () => onStop.emit();
    return { onStop, stop };
  }

  private drawerComponent(content: Type<unknown>, options: DrawerOptions): DrawerComponent {
    const onClosed = new EventEmitter();
    const close = () => onClosed.emit();
    const { data, ...instanceOptions } = options;
    options = { closeOnEsc: true, closeOnOutsideClick: true, ...instanceOptions };
    return { content, data, options, onClosed, close } as DrawerComponent;
  }

  // fixes error when spying ModalService on tests "failed to execute 'removeChild' on 'Node'"
  private modalComponent(content: Type<unknown> | string, options?: { title?: string, isLarge?: boolean, isInverse?: boolean, type: 'dialog' | 'confirm' | 'alert', data?: Accessible }): ModalComponent {
    const onClosed = new EventEmitter();
    return { content, ...options, onClosed } as ModalComponent;
  }
}
