import { Component } from '@angular/core';
import { WidthState, Themes, Size, ButtonKind } from '@usitsdasdesign/dds-ng/shared';
import { ProfileOptions } from '@usitsdasdesign/dds-ng/profile';
import { ButtonOptions } from '@usitsdasdesign/dds-ng/button';
import { HeaderOptions, HeaderElmnts } from '@usitsdasdesign/dds-ng/header';
import { ProfileModule } from '@usitsdasdesign/dds-ng/profile';
import { ContextMenuModule } from '@usitsdasdesign/dds-ng/context-menu';
import { HeaderModule } from '@usitsdasdesign/dds-ng/header';
import { ButtonModule } from '@usitsdasdesign/dds-ng/button';

@Component({
  selector: 'app-header',
  standalone: true,
  providers:[],
  imports: [ProfileModule,ContextMenuModule,HeaderModule,ButtonModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.sass'
})
export class HeaderComponent {
  headerOptions: HeaderOptions = {
    name: 'Skills Management Tool',
    width: WidthState.full || 'full',
    theme: "Green",
    isResponsive: true,
    isInverse: false,
    customClass: ''
  };

  responsivePriority: HeaderElmnts[] = [
    HeaderElmnts.logo,
    HeaderElmnts.projectName ,
    HeaderElmnts.profile || null,
    HeaderElmnts.nav,
    HeaderElmnts.icons,
    HeaderElmnts.search
  ];

  buttonNavOptions: ButtonOptions = {
    theme: 'dark',
    kind: ButtonKind.primaryLoud,
    size: Size.lg,
  };

  button1Options: ButtonOptions = {
    theme: Themes.dark,
    icon: 'dds-icon_sm dds-icon_external-link'
  };

  button2Options: ButtonOptions = {
    theme: Themes.dark,
    icon: 'dds-icon_sm dds-icon_chat'
  };

  button3Options: ButtonOptions = {
    theme: Themes.dark,
    icon: 'dds-icon_sm dds-icon_bookmark'
  };

  profileOptions: ProfileOptions = {
    username: localStorage.getItem('name') || '',
    nameLetter: this.findUserInitials() || '',
    userInfo: localStorage.getItem('roleName') || ''
  };

  findUserInitials() {
    const names = localStorage.getItem('name')?.split(',');
    let userInitials = '';
    for (const name of names || '') {
      userInitials = userInitials + name.trim()[0];
    }

    return userInitials;
  }
  isValidUser(): boolean {
    return localStorage.getItem('role') !== 'undefined' ? true : false;
  }
}

