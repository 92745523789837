<div class="app-main-wrapper">
    @if (isUserLoggedCallFinished) {
        <div class="header-wrapper">
            <app-header/>
        </div>
    
        <div class="outlet-main-wrapper">
            @if(isValidUser){
            <app-side-bar (transitionstart)="onTransitionStart()" (transitionend)="onTransitionEnd()"/>
        }
            <div #scrollable class="scrollable-content">
                <div #container class="dds-container main-container">
                    <div class="dds-row">
                        <div class="dds-col">
                            <router-outlet />
                        </div>
                    </div>
                </div> 

            <!--  <div #footer class="footer-wrapper">
                    <app-footer/>
                </div> -->
            </div>
        </div>
    } @else {
        <div #container class="dds-container sppiner-container">
            <div class="dds-row">
                <div class="dds-col">
                    <dds-spinner size="100px"></dds-spinner>
                </div>
            </div>
        </div>
    }
</div>

