import { Component, ElementRef, EventEmitter, inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SuggestionsTagsInputModule } from '@usitsdasdesign/dds-ng/suggestions-tags-input';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { SelectItemOptions, SelectModule } from '@usitsdasdesign/dds-ng/select';
import { ButtonModule } from '@usitsdasdesign/dds-ng/button';
import { debounceTime, distinctUntilChanged, finalize, Subject, Subscription, take, takeUntil } from 'rxjs';
import { UserService } from '../../../shared/services/user.service';
import { DrawerComponent } from '../../../shared/components/drawer/drawer.component';
import { ToastModule } from '@usitsdasdesign/dds-ng/toast';
import { User } from '../../../shared/model/user';

@Component({
  selector: 'app-user-form',
  standalone: true,
  imports: [ ButtonModule, ReactiveFormsModule, SelectModule, SuggestionsTagsInputModule, ToastModule ],
  templateUrl: './user-form.component.html',
  styleUrl: './user-form.component.sass',
})
export class UserFormComponent implements OnInit, OnDestroy {
  @ViewChild('personInput') personInput: ElementRef | any;
  private subscription = new Subscription;
  private saveUserSubscription = new Subscription;
  public cancelPersonListEmitter: EventEmitter<void> = new EventEmitter<void>();
  public selectRoleFormCtrl = new FormControl({ value: '', disabled: false });
  public selectUserFormCtrl = new FormControl({ value: '', disabled: false });
  
  public isPersonListLoading: boolean = false;
  public isUserSelected: boolean = false;
  public isRoleSelected: boolean = true;
  public searchList: string[] = [];
  public roleItems: SelectItemOptions[] = [];
  public userData!: Partial<User>;
  public roleCount = {admin:0,adminApprover:0};
  public initialsName!: string;

  private userService: UserService = inject(UserService);
  private drawerComponent: DrawerComponent = inject(DrawerComponent);
  private elRef: ElementRef = inject(ElementRef);
  userSearchSubject = new Subject<string>();

  async ngOnInit(): Promise<void> {
    const userService = this.userService.getUserRole().pipe(take(1))
    .subscribe((response) => response.data?.forEach((role) => this.roleItems.push({ heading: role.role_name, value: role.id })));
    this.subscribeUserSearch();
    this.subscription.add(userService);
  }

  ngAfterViewInit(): void {
    const inputElement: HTMLInputElement | null = this.elRef?.nativeElement.querySelector('input.dds-suggestions-tags-input__field');
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.saveUserSubscription.unsubscribe();
  }

  public async changeUserValue(): Promise<void> {
    this.isPersonListLoading = true;
    const userFormValue = this.selectUserFormCtrl.value?.trim() ?? '';
    const searchTerm = userFormValue.toLowerCase();
    const searchCriteria = searchTerm.includes('/') ? searchTerm.split('/')[1].trim() : searchTerm.trim();
    this.isUserSelected = !!userFormValue && userFormValue.includes("/");

    this.userSearchSubject.next(searchCriteria);
  }

  public valueChangedRole(): void {
    this.userData.role_id = parseInt(this.selectRoleFormCtrl.value!)
    this.isRoleSelected = false;
  }

  public saveUser(): void {
    this.saveUserSubscription = this.userService.saveUserRoleChange(this.userData).pipe(take(1))
      .subscribe(() => this.drawerComponent.close(true));
  }

  subscribeUserSearch() {
    const userSubject = this.userSearchSubject.pipe(debounceTime(500), distinctUntilChanged((previous, current) => {
      this.isPersonListLoading = previous !== current;
      return previous === current;
    })).subscribe(
      (string) => {
        this.searchList = [];
        this.cancelPersonListEmitter.emit();
        console.log(string);
        if (string) {
          this.subscription = this.userService.getUserByCriteria(string)
            .pipe(take(1), takeUntil(this.cancelPersonListEmitter), finalize(() => this.isPersonListLoading = false))
            .subscribe(response => {
              response.data.forEach((user) => {
                this.userData = user;
                this.searchList = [...this.searchList, `${user.first_name} ${user.last_name} / ${user.email}`];
                this.initialsName = `${user.first_name?.charAt(0)}${user.last_name?.charAt(0)}`.toUpperCase();
              });
          });
        } else {
          this.isPersonListLoading = false;
        }
      }
    );

    this.subscription.add(userSubject);
  }

  public isEscapeHideContext() {
    if (this.personInput && this.personInput.contextMenuShown) {
      this.personInput.contextMenuShown = false;
    }
  }
}
