import { Component, OnInit, ViewChild } from '@angular/core';
import { AuditLogService } from '../../../../shared/services/audit-log.service';
import { Accessible } from '../../../../shared/model/model';
import { take, takeUntil } from 'rxjs';
import { RouterModule } from '@angular/router';
import { PaginatedData } from '../../../../shared/model/model';
import { LogItem } from '../../../../shared/model/log-item';
import { ButtonComponent } from '@usitsdasdesign/dds-ng/button';
import { TableComponent, TableLoadEvent } from "../../../../shared/components/table/table.component";
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-last-mod-table',
  standalone: true,
  imports: [TableComponent, ButtonComponent, RouterModule, CommonModule],
  templateUrl: './last-mod-table.component.html',
  styleUrl: './last-mod-table.component.sass'
})
export class LastModTableComponent implements OnInit {
  @ViewChild(TableComponent) private table!: TableComponent;
  public latest: (LogItem & Accessible)[] = [];

  public columns = [
    { name: 'skillName', header: 'Skill Name', dataType: 'string', sortable: true },
    { name: 'type', header: 'Type', dataType: 'string', sortable: true },
    { name: 'decision', header: 'Decision', dataType: 'string', sortable: true },
    { name: 'requestor', header: 'Requestor', dataType: 'string', sortable: true },
    { name: 'processor', header: 'Processor', dataType: 'string', sortable: true },
    { name: 'requestDate', header: 'Request Date', dataType: 'date', sortable: true },
    { name: 'taxonomyChangeDate', header: 'Taxonomy Change Date', dataType: 'date', sortable: true }
  ];

  public isLoading: boolean = false;
  public isApprover: boolean = false
  public totalSize = 0;

  constructor(
    private auditLogService: AuditLogService
  ) { }

  ngOnInit(): void {
    this.isApprover = localStorage.getItem('role') === '1';
  }

  public async getModifications({ page, pageSize, sortingState }: TableLoadEvent) {
    this.isLoading = true;
    try {
      const { data, totalResults } = await new Promise<PaginatedData<LogItem>>(resolve =>
        this.auditLogService.getLogItems(page, pageSize, sortingState)
          .pipe(take(1), takeUntil(this.table.load))
          .subscribe(resolve)
      );
      this.latest = data.slice(0, 4).map(skill => new LogItem(skill));
      this.totalSize = totalResults;
    } finally {
      this.isLoading = false
    }

  }
}
