import { Component, Input, OnInit, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MasterSkillTag, newMasterSkillPayload, MasterSkillForEdition, MasterSkillPayloadForEdition } from '../../../../shared/model/masterSkill';
import { ReactiveFormsModule, FormsModule, FormControl, FormGroup, Validators, AsyncValidatorFn, AbstractControl, MaxLengthValidator } from '@angular/forms';
import { InputComponent } from '@usitsdasdesign/dds-ng/input';
import { SelectItemOptions, SelectModule } from '@usitsdasdesign/dds-ng/select';
import { ButtonModule } from '@usitsdasdesign/dds-ng/button';
import { MultiSelectModule } from '@usitsdasdesign/dds-ng/multi-select';
import { TagsModule } from '@usitsdasdesign/dds-ng/tags';
import { TagService } from '../../../../shared/services/tag.service';
import { SkillService } from '../../../../shared/services/skill.service';
import { DrawerComponent } from '../../../../shared/components/drawer/drawer.component';
import { catchError, firstValueFrom, map, of, Subscription, take } from 'rxjs';
import { DatePipe, CommonModule } from '@angular/common';
import { PopupService } from '../../../../shared/services/popup.service';
import { TagInputComponent } from '../../../../shared/components/tag-input/tag-input.component';
import { FocusHandlerModule } from '@usitsdasdesign/dds-ng/core/focus-handler';
import { SpinnerModule } from '@usitsdasdesign/dds-ng/spinner';
import { TextareaModule } from '@usitsdasdesign/dds-ng/textarea';


export interface ISubCategory {
  value: number, heading: string, parentCategoryId: number
}

@Component({
  selector: 'app-master-skill-form',
  standalone: true,
  imports: [FocusHandlerModule, TextareaModule, ReactiveFormsModule, FormsModule, InputComponent, SelectModule, ButtonModule, MultiSelectModule, TagsModule, CommonModule, SpinnerModule, TagInputComponent],
  providers: [DatePipe, PopupService],
  templateUrl: './master-skill-form.component.html',
  styleUrl: './master-skill-form.component.sass'
})
export class MasterSkillFormComponent implements OnInit {
  @Input() skillDataId!: number;

  public masterSkillForm!: FormGroup;
  public formHasChanges: boolean = false;
  formOriginalRawValue: string = '';
  categories: SelectItemOptions[] = [];

  subCategories: SelectItemOptions[] = [];
  filteredSubCategories: SelectItemOptions[] = [];

  fullTagsCollection: any[] = [];
  TAG_LIMIT = 100;

  subscriptions: Subscription = new Subscription();

  selectedTags: MasterSkillTag[] = [];
  addRequestorFieldVisible: boolean = false;
  addReqiestorBtnVisible: boolean = true;
  removeRequestorBtnVisible: boolean = false;
  action: string = '';
  originalSkillData: MasterSkillForEdition | null = null

  isApprover = localStorage.getItem('roleName') === 'Admin Approver';
  skillTypesList = [{
    heading: 'Specialized Skill',
    value: 1
  },
  {
    heading: 'Common Skill',
    value: 2
  },
  {
    heading: 'Certification',
    value: 3
  }];
  technologiesList = [{
    heading: 'Language',
    value: 1
  },
  {
    heading: 'Software',
    value: 2
  }];
  skillNameValdationError: string = '';

  checkingSkillName: string = ''
  skillHasPendingChanges: boolean = false;
  isLoading = true;

  constructor(
    private tagService: TagService,
    private skillService: SkillService,
    private drawer: DrawerComponent,
    private popUpService: PopupService
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.action = this.skillDataId ? 'Edit' : 'Create a New';
    this.getListsOfValues().then(() => {
      if (this.skillDataId) {
        this.getSkillData(this.skillDataId)
      } else {
        this.isLoading = false;
      }
    });
  }

  onFormPatched() {
    this.formOriginalRawValue = JSON.stringify(this.masterSkillForm.getRawValue()); // Save the original value of the form in an string to compare later
    this.subscriptions.add(this.masterSkillForm.valueChanges.subscribe(value => {
      this.formHasChanges = this.formOrTagsHasChanges();
    }));
    this.skillHasPendingChanges = this.originalSkillData?.hasPendingChanges || false;
    if (this.skillHasPendingChanges) {
      this.masterSkillForm.disable();

    }
  }


  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  async getListsOfValues() {
    const requests = [
      this.getTags(),
      this.getCategories(),
      this.getSubCategories()
    ]
    await Promise.all(requests);
  }

  createForm() {
    this.masterSkillForm = new FormGroup({
      skillName: new FormControl('', { asyncValidators: [this.skillNameValidator()], updateOn: 'blur' }),
      skillDescription: new FormControl('', [Validators.required]),
      skillDescriptionLink: new FormControl('', [Validators.pattern('https?://.+')]),
      skillCategory: new FormControl('', [Validators.required]),
      skillSubCategory: new FormControl('', [Validators.required]),
      skillType: new FormControl(''),
      skillTechnology: new FormControl(''),
      skillTags: new FormControl([]),
      skillRequestor: new FormControl('')
    });

    this.masterSkillForm.get('skillSubCategory')?.disable();
  }

  async getTags() {
    this.fullTagsCollection = await firstValueFrom(this.tagService.getMasterSkillsTags());
  }

  async getCategories() {
    this.categories = await firstValueFrom(this.skillService.getMasterSkillsCategories());
  }

  onCategoryChanges(selectedCategoryId: number) {
    if (this.subCategories.length > 0) {
      let subCatField = this.masterSkillForm.get('skillSubCategory')
      this.filteredSubCategories = this.subCategories.filter((subCat: any) => subCat.parentCategoryId == selectedCategoryId)
      subCatField?.setValue('')
      subCatField?.clearValidators();
      subCatField?.enable();
      subCatField?.setValidators([Validators.required]);
      subCatField?.updateValueAndValidity();
    }
  }

  async getSubCategories() {
    this.subCategories = await firstValueFrom(this.skillService.getMasterSkillsSubCategories());
  }

  onAddRequestorClick() {
    this.addRequestorFieldVisible = true;
    this.removeRequestorBtnVisible = true;
    this.addReqiestorBtnVisible = false;
    this.masterSkillForm.get('skillRequestor')?.setValidators([Validators.required, Validators.maxLength(77), Validators.pattern('^[a-zA-Z0-9._-]+@deloitte.com$')]);
    this.masterSkillForm.get('skillRequestor')?.updateValueAndValidity();
  }

  onRemoveRequestorClick() {
    this.addRequestorFieldVisible = false;
    this.removeRequestorBtnVisible = false;
    this.addReqiestorBtnVisible = true;
    this.masterSkillForm.get('skillRequestor')?.clearValidators();
    this.masterSkillForm.get('skillRequestor')?.reset();
  }
  onAddNew() {
    let payload: newMasterSkillPayload;
    //prepare dataObject to send to backend
    payload = {
      skillName: this.masterSkillForm.get('skillName')?.value,
      skillDescription: this.masterSkillForm.get('skillDescription')?.value,
      skillCategoryId: this.masterSkillForm.get('skillCategory')?.value,
      skillSubCategoryId: this.masterSkillForm.get('skillSubCategory')?.value,
      skillDescriptionUrl: this.masterSkillForm.get('skillDescriptionLink')?.value,
      skillTypeId: this.masterSkillForm.get('skillType')?.value ? Number(this.masterSkillForm.get('skillType')?.value) : null,
      requestInitiatorId: Number(localStorage.getItem('userId')),
      skillRequestor: this.masterSkillForm.get('skillRequestor')?.value,
      tagsId: this.masterSkillForm.get('skillTags')?.value,
      skillTechnologyId: Number(this.masterSkillForm.get('skillTechnology')?.value) === 0 ? null : Number(this.masterSkillForm.get('skillTechnology')?.value)
    }

    this.subscriptions.add(
      this.skillService.addNewMasterSkill(payload).subscribe({
        next: (res: any) => this.drawer.close(),
        error: (e: any) => console.error(e),
        complete: () => console.info('addNewMasterSkill complete')
      })
    );
  }

  getSkillData(skillId: number) {
    this.subscriptions.add(
      this.skillService.getMasterSkillById(skillId).subscribe({
        next: (res: any) => {
          this.originalSkillData = res;



          //prepare form and filtered values
          if (!this.isApprover && res.skillstatus == false) {
            this.masterSkillForm.disable();
          }
          this.masterSkillForm.get('skillSubCategory')?.enable();
          this.masterSkillForm.get('skillSubCategory')?.setValidators([Validators.required]);
          this.filteredSubCategories = this.subCategories.filter((subCat: any) => subCat.parentCategoryId == res.categoryId)
          this.masterSkillForm.get('skillTags')?.patchValue(res.tags);
          this.masterSkillForm.updateValueAndValidity();

          //populate form with data
          this.masterSkillForm.get('skillName')?.setValue(res.skillname);
          this.masterSkillForm.get('skillDescription')?.setValue(res.skillDescription);
          this.masterSkillForm.get('skillDescriptionLink')?.setValue(res.skillDescriptionUrl);
          this.masterSkillForm.get('skillDescriptionLink')?.markAllAsTouched();

          this.masterSkillForm.get('skillCategory')?.setValue(res.categoryId);
          this.masterSkillForm.updateValueAndValidity();
          this.masterSkillForm.get('skillSubCategory')?.setValue(res.subCategoryId);
          this.masterSkillForm.get('skillTechnology')?.setValue(Number(res.technology));
          this.masterSkillForm.get('skillType')?.setValue(Number(res.skilltype));

          if (res.skillRequestor) {
            this.addRequestorFieldVisible = true;
            this.removeRequestorBtnVisible = true;
            this.addReqiestorBtnVisible = false;
            this.masterSkillForm.get('skillRequestor')?.setValidators([Validators.required, Validators.maxLength(30)]);
            res.skillRequestor ? this.masterSkillForm.get('skillRequestor')?.setValue(res.skillRequestor) : this.masterSkillForm.get('skillRequestor')?.setValue('');
            this.masterSkillForm.get('skillRequestor')?.updateValueAndValidity();
          }
          if (!res.skillstatus) {
            this.masterSkillForm.disable();
          }
          this.onFormPatched();
          this.isLoading = false;
        },
        error: (e: any) => console.error(e)
      })
    );
  }

  async onChangeSkillRequest() {
    const tagsToSend: number[] = this.selectedTags.map((tag) => tag.id);

    const payload: MasterSkillPayloadForEdition = {
      skillName: this.masterSkillForm.get('skillName')?.value,
      skillId: this.originalSkillData?.skillid || 0,
      skillDescription: this.masterSkillForm.get('skillDescription')?.value,
      categoryId: this.masterSkillForm.get('skillCategory')?.value,
      subCategoryId: this.masterSkillForm.get('skillSubCategory')?.value,
      skillTypeId: this.masterSkillForm.get('skillType')?.value ? Number(this.masterSkillForm.get('skillType')?.value) : null,
      tags: this.masterSkillForm.get('skillTags')?.value,
      requestDescription: 'The request description',
      technologyId: Number(this.masterSkillForm.get('skillTechnology')?.value) === 0 ? null : Number(this.masterSkillForm.get('skillTechnology')?.value),
      skillRequestor: this.masterSkillForm.get('skillRequestor')?.value || '',
      skillDescriptionUrl: this.masterSkillForm.get('skillDescriptionLink')?.value,
      requestInitiatorId: Number(localStorage.getItem('userId'))
    }

    if (this.originalSkillData?.lightCastId) {
      payload.skillLightcastId = this.originalSkillData?.lightCastId
    }

    const response = await firstValueFrom(
      this.popUpService.confirmComment({
        title: 'Request Change',
        message: `Are you sure you want to send a <strong>Change Request</strong> for the skill: "<strong><u>${this.originalSkillData?.skillname}</u></strong>"?`,
        buttons: {
          cancel: { icon: '', text: 'Dismiss' },
          accept: 'Send Request',
        }
      }).onClosed
    );

    if (typeof response !== 'string') return;

    this.skillService.editMasterSkill({ ...payload, requestDescription: response })
      .pipe(take(1))
      .subscribe((res: any) => {
        this.drawer.close();
      });
  }

  async onChangeStatusSkill(newStatus: boolean) {
    const skillName = this.masterSkillForm.get('skillName')?.value || this.originalSkillData?.skillname;
    const message = newStatus ?
      `Are you sure you want to <strong>reactivate</strong> the skill: "<strong><u>${skillName}</u></strong>"?` :
      `Are you sure you want to <strong>request to retire</strong> the skill: "<strong><u>${skillName}</u></strong>" from the library?`;

    const buttons = {
      cancel: { icon: '', text: 'Dismiss' },
      accept: newStatus ? 'Reactivate Skill' : { icon: 'dds-icon_delete__l__stroke', text: 'Retire' },
    }

    const comment = await firstValueFrom(
      this.popUpService.confirmComment({ title: newStatus ? 'Reactivate Skill' : 'Retire Skill', message, buttons }).onClosed
    );

    if (typeof comment !== 'string') return;

    const spinner = this.popUpService.spinner();
    try {
      await firstValueFrom(this.skillService.changeDSTSkillStatus(this.skillDataId, comment, newStatus));
    } finally {
      spinner.stop();
      this.drawer.close();
    }
  }

  onNewTag(tagName: string) {
    if (tagName.length > this.TAG_LIMIT) {
      tagName = tagName.substring(0, this.TAG_LIMIT);
    }
    const spinner = this.popUpService.spinner();
    this.tagService.addNew(tagName).subscribe({
      next: (res: any) => {
        const newTag = { value: res, heading: tagName };
        this.fullTagsCollection.push(newTag);
        const value = this.masterSkillForm.get('skillTags')?.value ?? [];
        this.masterSkillForm.get('skillTags')?.patchValue([...value, res]);
      },
      error: (e: any) => console.error(e),
      complete: () => spinner.stop()
    })
  }

  compareTags(): boolean {
    let rawSelectedTags = JSON.stringify(this.masterSkillForm.get('skillTags')?.value.sort() || []);
    let rawOriginalTags = JSON.stringify(this.originalSkillData?.tags?.sort() || []);
    let tagsHasChanges = rawSelectedTags !== rawOriginalTags;
    return tagsHasChanges;
  }

  formOrTagsHasChanges(): boolean {
    const currentFormRawValue = JSON.stringify(this.masterSkillForm.getRawValue());
    let formHasChanges = this.formOriginalRawValue !== currentFormRawValue;
    let tagsHasChanges = this.compareTags();
    return (formHasChanges || tagsHasChanges);
  }
  skillNameValidator(): AsyncValidatorFn {
    this.checkingSkillName = '';
    return (control: AbstractControl) => {
      if (!control.value) {
        this.skillNameValdationError = 'Is required';
        this.checkingSkillName = '';
        return of({ isRequired: true });
      } else {
        this.skillNameValdationError = '';
        this.checkingSkillName = 'Checking name availability...';
        return this.skillService.validateSkillNameAvailability(control.value).pipe(
          take(1),
          map((res: any) => {
            this.checkingSkillName = ''
            if (res && (res.skillNameAvailable == false && (control.value != this.originalSkillData?.skillname))) {
              this.skillNameValdationError = 'A skill with this name already exists. Please choose a different name.';
              return { skillNameTaken: true };
            } else {
              this.checkingSkillName = ''

              return null;
            }

          }),
          catchError(() => {
            this.checkingSkillName = ''

            return of(null)
          })

        );
      }

    }

  }


}
