import { inject, Injectable } from '@angular/core';
import { SkillTaxonomy } from '../../views/skill-taxonomy/skill-taxonomy';
import { Observable} from 'rxjs';
import { SortOptions } from '@usitsdasdesign/dds-ng/shared';
import { HttpClient } from '@angular/common/http';
import { SkillFiltersParams } from '../../views/market-library/skill-filters';
import { SkillFiltersType } from '../../views/skill-taxonomy/skills-taxonomy-filters';
import { PaginatedData } from '../model/model';
import { getSettings } from '../utils/settingsLoader';

@Injectable({
  providedIn: 'root',
})
export class SkillTaxonomyService {
	private http: HttpClient = inject(HttpClient);
	settings = getSettings();

	public getSkills(page: number, pageSize: number, sort: SortOptions, query?: string, filters: SkillFiltersParams = {}): Observable<PaginatedData<SkillTaxonomy>> {
		const params = new URLSearchParams();
		params.append('PageSize', pageSize.toString());
		params.append('PageNumber', (page - 1).toString());

		if (sort.property?.length) {
			params.append('OrderBy', sort.property);
			params.append('IsAscending', sort.descending ? 'false' : 'true');
		}

		if (query?.length) {
			params.append('Search', query);
		}
		Object.keys(filters).forEach(key => {
			if (filters[key].length)
				params.append(key, filters[key]);
		});
		return this.http.get<PaginatedData<SkillTaxonomy>>(`${this.settings.apiUrl}/Skill/SkillsTaxonomy?${params.toString()}`);
  }
	public getFilters(): Observable<SkillFiltersType<{ id: number; description: string }>> {
    const params = new URLSearchParams();
    params.append('pageName', 'skill-taxonomy');
    return this.http.get<SkillFiltersType<{ id: number; description: string }>>(`${this.settings.apiUrl}/Skill/Filters?${params.toString()}`);
	}
}
