<div class="skill-detail-drawer">
	<div class="skill-information">
		<h3>Skill Information</h3>
		<hr />
		<div class="info-grid">
			<div>
        <p><strong>Skill Name</strong><br>{{skillInformation.pchName || 'No skill Name'}}</p>
			</div>
			<div>
				<p><strong>Lightcast ID</strong><br>{{skillInformation.skillLightCastId || 'No lightcast id'}}</p>
			</div>
		</div>
		<!-- Skill Description with Show More/Less functionality-->

		<div>
			<p><strong>Skill Description</strong><br>
			</p>
			<p class="m-comment" [ngClass]="{'truncated':!showFullText}"> {{skillInformation.pchDescription || 'No skill
				description'}}</p>
      @if(showMoreVisible(skillInformation.pchDescription)){
      <a class="show-more" (click)="toggleText()">{{ showFullText ? 'Show Less' : 'Show More' }}</a>
      }
		</div>
		<div class="info-grid">
			<p><strong>Skill Description Link</strong><br>
				{{skillInformation.pchLink || 'No skill description link'}}
			</p>
			<div class="link-icon-container">
				<a [href]="skillInformation.pchLink" target="_blank" aria-label="click to navigate to description link">
					<span class="link-icon dds-icon dds-icon_external_link__l__stroke"></span>
				</a>
			</div>
		</div>


		<div>
			<p><strong>Category</strong><br>
			</p>
			<p class="mtp">{{category || 'No category'}}</p>
		</div>
		<div>
			<p><strong>Subcategory</strong><br> </p>
			<p class="mtp">{{subCategory || 'No subcategory'}}</p>
		</div>
		<div class="info-grid">
			<div>
				<p><strong>Catalog</strong><br>{{skillInformation.sourceCatalog || 'No catalog'}}</p>
			</div>
			<p><strong>DST Status</strong><br>
				<span>
					{{skillInformation.dsTstatus || 'No status'}}
				</span>
			</p>
		</div>
		<div>
			<p><strong>Skill Type</strong><br></p>
			<p class="mtp">{{skillType || 'No skill type'}}</p>
		</div>


		<!-- Tags Section-->
		<div class="tags-section">
			<h4>TAGs</h4>
			@if (tags && tags.length > 0) {
			<ul>
				@for (tag of tags; track tag) {
				<li class="tag">{{tag}} </li>
				}
			</ul>
			} @else {
			<p>No tags available</p>
			}
		</div>
	</div>
</div>
