import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupService } from '../../shared/services/popup.service';
import { PendingChangeDetailsComponent } from './widgets/pending-change-details/pending-change-details.component';
import { ButtonComponent } from '@usitsdasdesign/dds-ng/button';
import { Column } from '@usitsdasdesign/dds-ng/shared';
import { TableRow, TableComponent, TableLoadEvent } from "../../shared/components/table/table.component";
import { PendingChanges } from '../../shared/model/pending-changes';
import { finalize, firstValueFrom, take, takeUntil } from 'rxjs';
import { Accessible, PaginatedData } from '../../shared/model/model';
import { SearchComponent } from '@usitsdasdesign/dds-ng/search';
import { MultiSelectComponent } from '@usitsdasdesign/dds-ng/multi-select';
import { FormsModule } from '@angular/forms';
import { PendingChangesFilters } from './pending-changes.filters';
import { DropdownModule } from '@usitsdasdesign/dds-ng/dropdown';
import { CheckboxComponent } from '@usitsdasdesign/dds-ng/checkbox';
import { PendingChangesService } from './pending-changes.service';
import { DrawerComponent } from '../../shared/components/drawer/drawer.component';
import { TableEmptyComponent } from "../../shared/components/table-empty/table-empty.component";
import { SelectComponent } from '@usitsdasdesign/dds-ng/select';
import { TooltipModule } from '@usitsdasdesign/dds-ng/tooltip';



@Component({
  selector: 'app-pending-changes',
  standalone: true,
  imports: [CommonModule, ButtonComponent, SearchComponent, TableComponent, SelectComponent, MultiSelectComponent, FormsModule, DropdownModule, CheckboxComponent, TableEmptyComponent, TooltipModule],
  templateUrl: './pending-changes.component.html',
  styleUrl: './pending-changes.component.sass'
})
export class PendingChangesComponent implements OnInit {
  @ViewChild(TableComponent) private table!: TableComponent;
  private service: PendingChangesService = inject(PendingChangesService);
  private popups: PopupService = inject(PopupService);
  private query: string = '';

  public skills: (PendingChanges & TableRow & Accessible)[] = [];
  public totalSize: number = 0;
  public isLoading: boolean = false;
  public isApprover: boolean = false;
  public drawer?: DrawerComponent
  public columns: Column[] = [
    { name: 'category', header: 'Category', dataType: 'string', sortable: true, width: '150px' },
    { name: 'subCategory', header: 'Subcategory', dataType: 'string', sortable: true, width: '150px' },
    { name: 'skillName', header: 'Skill Name', dataType: 'string', sortable: true, width: '150px' },
    { name: 'type', header: 'Type', dataType: 'string', sortable: true, width: '100px' },
    { name: 'requestShort', header: 'Request', dataType: 'string', sortable: false, width: '100px' },
    { name: 'requestDate', header: 'Request Date', dataType: 'date', sortable: true, width: '150px' },
    { name: 'dstStatus', header: 'DST Status', dataType: 'boolean', sortable: true, width: '150px' },
    { name: 'catalog', header: 'Catalog', dataType: 'string', sortable: true, width: '150px' },
    { name: 'tagsIds', header: 'Tags', dataType: 'string', sortable: false, width: '100px' }
  ];

  public filters: PendingChangesFilters = new PendingChangesFilters();

  public get selectedIds(): number[] {
    return this.skills.filter(skill => skill.tableRowSelected).map(skill => skill.id);
  }

  public async ngOnInit(): Promise<void> {
    try {
      this.filters = new PendingChangesFilters(await firstValueFrom(this.service.getFilters()));
      this.isApprover = localStorage.getItem('role') === '1';
      const formSubs = this.service.onApproveOrDeclineEnded.subscribe((
        (event: boolean) => {
          if (event == true) {
            this.drawer?.close();
            this.table.reload();
          }
        }
      ));

    } catch { }
  }

  public async getSkills({ page, pageSize, sortingState }: TableLoadEvent) {
    this.isLoading = true;
    try {
      const { data, totalResults } = await new Promise<PaginatedData<PendingChanges>>(resolve =>
        this.service.getSkills(page, pageSize, sortingState, this.query, this.filters.forParams())
        .pipe(take(1), takeUntil(this.table.load))
        .subscribe(resolve)
      );
      this.skills = data.map(skill => new PendingChanges(skill));
      this.totalSize = totalResults;
    } finally {
      this.isLoading = false;
      this.table.scrollTop();
    }
  }

  public searchSkills(search: string) {
    if (search.length > 0 && search.length < 3 || search === this.query)
      return;

    this.query = search;
    this.reload();
  }

  public clearFilters() {
    this.filters.clear();
    this.reload();
  }

  public reload() {
    this.table.reload({ page: 1 });
  }

  public async approve() {
    const comment = await firstValueFrom(
      this.popups.confirmComment({
        title: 'Approve Skills Changes',
        message: `Are you sure you want to <strong>approve</strong> the selected (${this.selectedIds.length}) skills?`,
        buttons: { accept: `Approve (${this.selectedIds.length}) Skills`, cancel: 'Dismiss' },
        isOptional: true
      }).onClosed
    )
    if (typeof comment !== 'string') return;

    const spinner = this.popups.spinner();
    this.service.bulkApprove(this.selectedIds, comment)
      .pipe(take(1), finalize(() => spinner.stop()))
      .subscribe(() => this.reload());
  }

  public async decline() {
    const comment = await firstValueFrom(
      this.popups.confirmComment({
        title: 'Decline Skills Changes',
        message: `Are you sure you want to <strong>decline</strong> the selected (${this.selectedIds.length}) skills?`,
        buttons: { accept: { theme: 'danger', text: `Decline (${this.selectedIds.length}) Skills` }, cancel: 'Dismiss' }
      }).onClosed
    )
    if (typeof comment !== 'string') return;

    const spinner = this.popups.spinner();
    this.service.bulkDecline(this.selectedIds, comment)
      .pipe(take(1), finalize(() => spinner.stop()))
      .subscribe(() => this.reload());
  }

  public async viewSkillDetails(event: MouseEvent, skillId: number) {
    // Prevent the skill details from opening when clicking the checkbox
    if (event.target instanceof HTMLElement && event.target.tagName === 'DDS-CHECKBOX') return;
    this.drawer = this.popups.drawer(PendingChangeDetailsComponent, { data: { pchSkillId: skillId } });
  }
  getTooltipText(tagList: string[]): string {
    return tagList.join(', \n');
  }

}







