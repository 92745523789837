<dds-header [name]="headerOptions.name!"
            [theme]="headerOptions.theme!"
            [width]="headerOptions.width!"
            [customClass]="headerOptions.customClass!"
            [isResponsive]="headerOptions.isResponsive!"
            [isInverse]="headerOptions.isInverse!"
            [responsivePriority]="responsivePriority">

  <dds-logo logo></dds-logo>


@if(isValidUser()==true){
  <ng-container profile>
    <dds-profile [username]="profileOptions.username!"
                 [nameLetter]="profileOptions.nameLetter!"
                 [userInfo]="profileOptions.userInfo!"
                 role="menuitem">

      <!-- <dds-profile-item>
        Report an issue
      </dds-profile-item> -->
      <dds-profile-item>
        Log out
      </dds-profile-item>
    </dds-profile>
  </ng-container>
}
@if(isValidUser()==true){
  <ng-container mobileNavList role="navigation">
      <dds-context-menu size="lg">
        <dds-context-menu-item ariaLabel="Master Taxonomy">
          Master Taxonomy
        </dds-context-menu-item>
        <dds-context-menu-item ariaLabel="User Management">
          User Management
        </dds-context-menu-item>
        <dds-context-menu-item ariaLabel="Pending Approvals">
          Pending Approvals
        </dds-context-menu-item>
        <dds-context-menu-item ariaLabel="Activity Log">
          Activity Log
        </dds-context-menu-item>
        <dds-context-menu-item ariaLabel="General Settings">
          General Settings
        </dds-context-menu-item>
      </dds-context-menu>
    </ng-container>
  }
  </dds-header>
