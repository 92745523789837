import { Component, ElementRef, inject, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { HeaderComponent } from './shared/components/header/header.component';
import { SideBarComponent } from './shared/components/side-bar/side-bar.component';
import { configService } from './shared/services/config.service';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
//import { Title } from '@angular/platform-browser';
import { filter, lastValueFrom, map } from 'rxjs';
import { EventMessage, EventType } from '@azure/msal-browser';
import { SpinnerComponent } from '@usitsdasdesign/dds-ng/spinner';
import { UserService } from './shared/services/user.service';



@Component({
    selector: 'app-root',
    standalone: true,
    templateUrl: './app.component.html',
    styleUrl: './app.component.sass',
  imports: [RouterOutlet, HeaderComponent, SideBarComponent, SpinnerComponent]
})
export class AppComponent {
  private renderer: Renderer2 = inject(Renderer2);
  @ViewChild('container') private container!: ElementRef<HTMLDivElement>;
  @ViewChild('scrollable') private scrollable!: ElementRef<HTMLDivElement>;
  loading: boolean = true;
  isUserLoggedCallFinished = false;
  isValidUser: boolean = false

  constructor(
    public viewRef: ViewContainerRef, 
    private configService: configService,
    private msalService: MsalService,
    private broadcastService: MsalBroadcastService,
    private router: Router,
    private userService: UserService
  ) {
  }
  
  async ngOnInit() {
    await lastValueFrom(this.msalService.initialize());
    await this.msalService.instance.handleRedirectPromise();
    this.findAndSetActiveAccount();
    console.log(this.configService.getAllSettings());
    if (this.msalService.instance.getAllAccounts().length < 1) {
      sessionStorage.removeItem('msal.interaction.status')
      localStorage.removeItem('msal.interaction.status')
      await this.login();
    } else {
      this.evaluateRedirection();
    }
    this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      ).subscribe((result: EventMessage) => {
        this.evaluateRedirection();
      });
  }

  onTransitionStart(): void {
    this.renderer.setStyle(this.scrollable.nativeElement, 'overflow', 'hidden');
  }

  onTransitionEnd(): void {
    this.renderer.removeStyle(this.scrollable.nativeElement, 'overflow');
  }

  async login() {
    const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

    await this.msalService.instance.loginRedirect();
  }

  public findAndSetActiveAccount() {
    let activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
      return accounts[0];
    }

    return activeAccount;
  }
  
  private evaluateRedirection() {
    this.userService.getUserLoggedRole().subscribe((res: any) => {
      this.isUserLoggedCallFinished = true
      if (!res?.[0]?.rol) {
        this.router.navigate(['unauthorized']);
      } else {
        this.isValidUser = true
      }
    });
  }
}
