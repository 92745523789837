import { Component } from '@angular/core';
import { FooterModule } from '@usitsdasdesign/dds-ng/footer';
import { ResizeHandlerService } from '@usitsdasdesign/dds-ng/shared/resize-handler';

@Component({
  selector: 'app-unauthorized',
  standalone: true,
  imports: [FooterModule],
  providers: [ResizeHandlerService],
  templateUrl: './unauthorized.component.html',
  styleUrl: './unauthorized.component.sass'
})
export class UnauthorizedComponent {

}
