export class SkillTaxonomy {
	skillid: number;
	skillname: string;
	category: string;
	subcategory: string;
	skillstatus: boolean | null;
	dststatuslabel: string;
    skilltype: string;
	skilldescription: string;
	startdate?: string;
	enddate?: string;
	tags: string;
	listedTags?: string[];
	catalog: string;

	constructor(data: Partial<SkillTaxonomy> & { skillId?: number; skillName?: string; category?: string; subcategory?: string; skillStatus?: boolean | null; dstStatuslabel?: string; skillType?: string; skillDescription?: string; startDate?: string; endDate?: string; catalog?: string }) {
		this.skillid = data.skillId ?? 0;
		this.skillname = data.skillName ?? '';
		this.category = data.category ?? '';
		this.subcategory = data.subcategory ?? '';
		this.skillstatus = data.skillStatus ?? null;
		this.dststatuslabel = data.dstStatuslabel ?? '';
		this.skilltype = data.skillType ?? '';
		this.skilldescription = data.skillDescription ?? '';
		this.startdate = data.startDate ?? '';
		this.enddate = data.endDate ?? '';
		this.tags = data.tags ?? '';
		this.catalog = data.catalog ?? '';
		this.listedTags = this.tags.split(',').map(tag => tag.trim());
	}
}
