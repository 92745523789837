<div class="main-wrapper">
<div class="dds-row">
  <div class="dds-col">
    <h2>Role Management</h2>
  </div>
</div>
<div class="dds-row">
  <div class="dds-col">
    <i class="dds-icon dds-icon_info__s__stroke"></i>For optimal management we suggest maintaining a maximum of 3 Admin
    Approvers and 10 Admin Users.
    
    <label class="search-label dds-mt-2">
      <b>Search Person</b>
    </label>
    <dds-suggestions-tags-input #personInput [formControl]="selectUserFormCtrl"
                            ariaLabel="Type a person name"
                            placeholder="Type a person name"
                            stickerMaxHeight="200"
                            [searchList]="searchList"
                            (valueChanged)="changeUserValue()"
                            [isLoading]="isPersonListLoading"
                            (keydown.escape)="isEscapeHideContext()"
                            >
    </dds-suggestions-tags-input>
    @if (!searchList.length && !!personInput.value.length) {
      <p>No results match your search criteria</p>
    }
  </div>
</div>

@if (isUserSelected) {
  <div class="dds-row dds-mt-4">
    <div class="dds-col">
      @if (userData.profile_pic && userData.profile_pic.length>0) {
      <div class="dds-col custom_img_profile">
        <img [src]="userData.profile_pic" class="avatar" alt="User profile image">
      </div>
      }@else {
      <div class="initials-avatar">
        {{initialsName}}
      </div>
      }
    </div>

    <div class="dds-col-10">
      <div class="dds-row">
        <div class="dds-col">
          <div class="attr-label">Name</div>
          <div class="attr-content">{{userData.first_name}} {{userData.last_name}} </div>
        </div>
      </div>
      <div class="dds-row">
        <div class="dds-col">
          <div class="attr-label">Job Title</div>
          <div class="attr-content">{{userData.job_Title || 'No data found.'}}</div>
        </div>
      </div>
      <div class="dds-row">
        <div class="dds-col">
          <div class="attr-label">Email</div>
          <div class="attr-content">{{userData.email || 'No data found.'}}</div>
        </div>
        
        </div>
        <div class="dds-row">
          <div class="dds-col-12">
            <div class="attr-label">Role</div>
            <div class="attr-content">
              <dds-select [formControl]="selectRoleFormCtrl" [list]="roleItems" (valueChanged)="valueChangedRole()">
              </dds-select>
            </div>
  
        </div>
        </div>
    </div>
    </div>
  <div class="dds-row footer-buttons">
    <dds-button (click)="saveUser()" theme="dark" role="button" icon=" dds-icon_add_user__l__stroke"
      ariaLabel="Add New User" [disabled]="isRoleSelected">Add New</dds-button>
  </div>
}

</div>

