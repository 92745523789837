<div class="dds-row">
	<div class="dds-col">
		<div class="section-title">
			Pending Changes
		</div>

		<div class="search-bar">
			<dds-search #search placeholder="Search in Pending Changes by Skill Name, Category, or Subcategory"
				[ariaLabel]="search.placeholder" [isTrimValue]="true" size="lg"
				(valueChanged)="searchSkills($event)"></dds-search>
		</div>

		<div class="filters">
			<dds-select label="Category" ariaLabel="Category" type="counter" theme="dark" stickerWidth="auto" stickerMaxHeight="400" [list]="filters.lists.categories" [(ngModel)]="filters.filters.categories" (valueChanged)="filters.clear('subCategories'); reload()" />
			<dds-multi-select label="Subcategory" ariaLabel="Subcategory" type="counter" theme="dark" stickerWidth="auto" stickerMaxHeight="400" [list]="filters.computedLists.subCategories" [(ngModel)]="filters.filters.subCategories" (valueChanged)="reload()" />
			<dds-multi-select label="Modification Type" ariaLabel="Modification Type" type="counter" theme="dark"
				stickerWidth="auto" stickerMaxHeight="400" [list]="filters.lists.typesPendingChange"
				[(ngModel)]="filters.filters.typesPendingChange" (valueChanged)="reload()" />
			<dds-multi-select label="DST Status" ariaLabel="DST Status" type="counter" theme="dark" stickerWidth="auto"
				stickerMaxHeight="400" [list]="filters.lists.dmtStates" [(ngModel)]="filters.filters.dmtStates"
				(valueChanged)="reload()"></dds-multi-select>

			<dds-multi-select label="Catalog" ariaLabel="Catalog" type="counter" theme="dark" stickerWidth="auto"
				stickerMaxHeight="400" [list]="filters.lists.catalogs" [(ngModel)]="filters.filters.catalogs"
				(valueChanged)="reload()"></dds-multi-select>

			<dds-multi-select label="Tags" ariaLabel="Tags" type="counter" theme="dark" stickerWidth="auto"
				stickerMaxHeight="400" [list]="filters.lists.tags" [(ngModel)]="filters.filters.tags"
				(valueChanged)="reload()"></dds-multi-select>
			<dds-button [disabled]="!filters.isFiltered" theme="dark" icon="dds-icon_close" role="button" ariaLabel="Clear"
				(clicked)="clearFilters()">Clear</dds-button>
		</div>
		
		<div class="selectable-status" [hidden]="!table.selectedCount">
			<div class="selectable-status-info">
				{{ table.selectedCount }} Item{{ table.selectedCount > 1 ? 's' : '' }} Selected
			</div>
			<div class="selectable-status-actions">
				<dds-button theme="danger" kind="primary" icon="dds-icon_close" role="button" ariaLabel="Decline"
					(clicked)="decline()">
					Decline
				</dds-button>
				<dds-button theme="green" kind="primary" icon="dds-icon_check" role="button" ariaLabel="Approve"
					(clicked)="approve()">
					Approve
				</dds-button>
			</div>
		</div>

		<app-table #table [initialSort]="{ property: 'requestDate', descending: true }" [class.with-selection]="!!table.selectedCount" [columns]="columns" [isLoading]="isLoading"
			[totalSize]="totalSize" [selectable]="isApprover" (load)="getSkills($event)"
			(onSelectAll)="table.selectAllRows($event, skills)">
				@if (skills.length === 0) {
					<app-table-empty [colspan]="columns.length + (isApprover ? 1 : 0)" />
				}
				
				@for (skill of skills; track skill.id) {
					<tr class="dds-data-table__row
															" [class.dds-data-table__row_selected]="skill.tableRowSelected" (click)="viewSkillDetails($event, skill.id)">
						@if (isApprover) {
							<td class="dds-data-table__cell">
								<dds-checkbox theme="dark" [ariaLabel]="'skill ' + ($index + 1)" [value]="!!skill.tableRowSelected"
								(click)="table.toggleRow(skill, skills)"></dds-checkbox>
							</td>
						}
						@for (column of columns; track column.name) {
							<td class="dds-data-table__cell clickeable">
								@switch (column.name) {
									@case ('dstStatus') {
										<span class="badge" [class.black]="skill.dstStatus === false" [class.wire]="skill.dstStatus === null">
											{{ skill.dstStatusLabel }}
										</span>
									}
									@case ('tagsIds') {
										@if (skill.tagsDescription.length > 0) {
											<span class="dds-counter" style="background: #007cb0;color:#fff" ddsTooltip="{{getTooltipText(skill.tagsDescription)}}"
												tooltipInvokeType="hover" tooltipPosition="left"
												[tooltipHasBeak]="true" [tooltipIsOutsideClick]="true" [tooltipIsDynamic]="true" tooltipTheme="blue"
												[tooltipShowDelay]="300" [tooltipHideDelay]="100">
												{{skill.tagsIds.length}}
											</span>
										}
									}
									@default {
										@if (column.dataType == 'date') {
											{{ skill[column.name] | date: 'MM/dd/yyyy' }}
										} @else {
											{{ skill[column.name] }}
										}
									}
								}
							</td>
						}
					</tr>
				}
		</app-table>
	</div>
</div>