import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { ButtonComponent } from '@usitsdasdesign/dds-ng/button';
import { Column } from '@usitsdasdesign/dds-ng/shared';
import { TableComponent, TableLoadEvent } from "../../shared/components/table/table.component";
import { SkillTaxonomy } from './skill-taxonomy';
import { SkillTaxonomyService } from '../../shared/services/skill-taxonomy.service';
import { firstValueFrom, take, takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';
import { Accessible, DataExportParams, PaginatedData } from '../../shared/model/model';
import { ToastService } from '@usitsdasdesign/dds-ng/toast';
import { ExportService } from '../../shared/services/export.service';
import { DropdownComponent, DropdownItemComponent } from '@usitsdasdesign/dds-ng/dropdown';
import { PopupService } from '../../shared/services/popup.service';
import { MasterSkillFormComponent } from './widgets/master-skill-form/master-skill-form.component';
import { SearchComponent } from '@usitsdasdesign/dds-ng/search';
import { DatepickerModule } from '@usitsdasdesign/dds-ng/datepicker';
import { MultiSelectComponent } from '@usitsdasdesign/dds-ng/multi-select';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SkillTaxonomyFilters } from './skills-taxonomy-filters';
import { TableEmptyComponent } from '../../shared/components/table-empty/table-empty.component';
import { SelectComponent } from '@usitsdasdesign/dds-ng/select';
import { TooltipModule } from '@usitsdasdesign/dds-ng/tooltip';

@Component({
  selector: 'app-skills-taxonomy',
  standalone: true,
  imports: [ButtonComponent, TableComponent, CommonModule, DropdownComponent, DropdownItemComponent, SearchComponent,
    DatepickerModule, SearchComponent, SelectComponent, MultiSelectComponent, FormsModule, ReactiveFormsModule, TableEmptyComponent, TooltipModule],
  templateUrl: './skill-taxonomy.component.html',
  styleUrl: './skill-taxonomy.component.sass'
})
export class SkillTaxonomyComponent implements OnInit {
  @ViewChild(TableComponent) private table!: TableComponent;
  private skillTaxonomyService: SkillTaxonomyService = inject(SkillTaxonomyService);
  private exportService: ExportService = inject(ExportService);
  private toastService: ToastService = inject(ToastService);
  private popupService: PopupService = inject(PopupService);
  private query: string = '';
  public skills: (SkillTaxonomy & Accessible)[] = [];
  public totalSize: number = 0;
  public isLoading: boolean = false;
  public isExportEnabled: boolean = false;
  public filters: SkillTaxonomyFilters = new SkillTaxonomyFilters();
  public addSkillButtonVisible: boolean = true;
  daterangepickerFormCtrl = new FormControl({ value: '', disabled: false });



  public columns: Column[] = [
    { name: 'category', header: 'Category', dataType: 'string', sortable: true, width: '120px' },
    { name: 'subcategory', header: 'Subcategory', dataType: 'string', sortable: true, width: '120px' },
    { name: 'skillname', header: 'Skill Name', dataType: 'string', sortable: true, width: '120px' },
    { name: 'skilldescription', header: 'Skill Description', dataType: 'string', sortable: false, width: '180px' },
    { name: 'skilltype', header: 'Skill Type', dataType: 'string', sortable: true, width: '100px' },
    { name: 'startdate', header: 'Start Date', dataType: 'date', sortable: true, width: '100px' },
    { name: 'enddate', header: 'End Date', dataType: 'date', sortable: true, width: '100px' },
    { name: 'catalog', header: 'Catalog', dataType: 'string', sortable: true, width: '100px' },
    { name: 'skillstatus', header: 'DST Status', dataType: 'boolean', sortable: true, width: '100px' },
    { name: 'tags', header: 'Tags', dataType: 'string', sortable: false, width: '100px' }
  ];

  async ngOnInit() {
    const role = localStorage.getItem('role');
    this.isExportEnabled = role == '1' || role == '2';
    this.filters = new SkillTaxonomyFilters(await firstValueFrom(this.skillTaxonomyService.getFilters()));
    this.addSkillButtonVisible = (localStorage.getItem('role')?.toString() !== '3') ? true : false;
  }

  public async loadSkills({ page, pageSize, sortingState }: TableLoadEvent) {
    this.isLoading = true;
    try {
      const { data, totalResults } = await new Promise<PaginatedData<SkillTaxonomy>>(resolve =>
        this.skillTaxonomyService.getSkills(page, pageSize, sortingState, this.query, this.filters.forParams())
          .pipe(take(1), takeUntil(this.table.load))
          .subscribe(resolve)
      );
      this.skills = data.map(skill => new SkillTaxonomy(skill));
      this.totalSize = totalResults;
    } finally {
      this.isLoading = false;
      this.table.scrollTop();
    }
  }

  openFormInDrawer(skillID?: number) {

    this.popupService.drawer(MasterSkillFormComponent, { data: { skillDataId: skillID } });

  }


  public searchSkillsTax(search: string) {
    if (search.length > 0 && search.length < 3 || search === this.query)
      return;

    this.query = search;
    this.reload();
  }

  public reload() {
    this.table.reload({ page: 1 });
  }

  public async export(fileType: string) {
    const params: DataExportParams = {
      fileType: fileType,
      OrderBy: this.table.sortingState.property || '',
      IsAscending: !this.table.sortingState.descending,
      Search: this.query,
      filters: this.filters.forParams()
    }

    try {
      await firstValueFrom(this.exportService.exportSkillTaxonomy(params));
      this.toastService.createToast({ title: 'Data has been exported', position: 'top-center', isLoading: false, lifeTime: 2500, isCloseIcon: true });
    } catch {
      this.toastService.createToast({ title: 'Download failed.', message: 'Please try again.', position: 'top-center', isLoading: false, lifeTime: 2500, isError: true, isCloseIcon: true });
    }
  }
  public clearFilters() {
    this.filters.clear();
    this.daterangepickerFormCtrl.reset();
    this.reload();
  }
  public dateChanged() {
    if (this.daterangepickerFormCtrl.value && this.daterangepickerFormCtrl.value[0] && this.daterangepickerFormCtrl.value[1]) {
      const startDate = new Date(this.daterangepickerFormCtrl.value[0]).toISOString().slice(0, 10);
      const endDate = new Date(this.daterangepickerFormCtrl.value[1]).toISOString().slice(0, 10);
      this.filters.filters.startDate = [startDate];
      this.filters.filters.endDate = [endDate];
      this.reload();
    }
  }

  getTooltipText(tagList: string[]): string {
    return tagList.join(', \n');
  }
}
