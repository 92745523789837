<div class="dds-row">
    <div class="dds-col">
        <hr class="dds-hr dds-mt-5 green-thick-hr" />
        <div class="dds-mt-4 dds-h3 ">Permission required</div>
        <div class="dds-mt-5 dds-mb-5 dds-h4">You need additional permissions to access the Skills Management Tool</div>
        <div>To request access, please use the <a class="custom-link"
                href="https://app.smartsheet.com/b/form/668a8ee312a44f95b8f91f0d5ecae5e3">
                Skills Management Tool Access Request Form</a>
        </div>
        <div>
            If you have any questions or concerns, please contact the
            <a class="custom-link" href="mailto:usfirmwideskillsstrategy@deloitte.com">
                US Firmwide Skills Strategy Team.
            </a>
            <br />
            Thank you for your patience.
        </div>
        </div>
        <dds-footer class="in-view-footer" [isInverse]="false" ariaLabel="footer" [role]="'contentinfo'">
            <div content>
                <p>
                    Deloitte refers to one or more of Deloitte Touche Tohmatsu Limited (“DTTL”), its global network of member
                    firms, and their related entities (collectively, the “Deloitte organization”). DTTL (also referred to as
                    “Deloitte Global”) and each of its member firms and related entities are legally separate and independent
                    entities, which cannot obligate or bind each other in respect of third parties. DTTL and each DTTL member
                    firm and related entity is liable only for its own acts and omissions, and not those of each other. DTTL
                    does not provide services to clients.
                    Please see <a href="http://www.deloitte.com/about" ddsFocus class="dds-link dds-link_dark"
                        target="_blank">www.deloitte.com/about</a> to learn more.
                </p>
                <p class="dds-footer__text">
                    © 2025 For information, contact Deloitte US Firm.
                </p>
            </div>
</dds-footer>
</div>