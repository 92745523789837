<div class="skill-detail-drawer">
  <div class="dds-row">
    <h2 class="form-title">{{ action }} Skill</h2>
  </div>
  @if(this.skillDataId && this.originalSkillData?.hasPendingChanges === true){
  <div class="dds-conten pending-change-alert">
    <h3>Action required</h3>
    <p>
      A request for this skill has already been submitted. Further action is required to initiate a new
      request.
    </p>
    <hr style="color:#330000" />
  </div>
  }
  <div class="dds-row second-header-row">
    <div class="dds-col">
      <div class="skill-information">
        <h3>Skill Information</h3>
      </div>
    </div>

    @if(this.skillDataId){

    <div class="dds-col">
      <p>Start Date: {{ originalSkillData?.startDate | date : "shortDate" }}</p>
      <p>
        End Date: {{ (originalSkillData?.endDate | date : "shortDate") || "N/A"}}
      </p>
    </div>
    }
  </div>

  <hr />
  @if(!isLoading){
  @if(this.skillDataId){

  <p><strong>Lightcast ID: </strong>{{ originalSkillData?.lightCastId }}</p>
  }
  <form [formGroup]="masterSkillForm">

    <div class="dds-row nameidRow">
      <div class="dds-col ">
        <dds-input class="dds-col" formControlName="skillName" id="skillName" label="Name" labelPosition="internal"
          placeholder="Enter skill name" [isRequired]="true" [isError]="
                masterSkillForm.controls['skillName'].invalid &&
                (masterSkillForm.controls['skillName'].dirty ||
                 masterSkillForm.controls['skillName'].touched)" [errorState]="'focusOut'"
          errorMessage="{{skillNameValdationError}}" ariaLabel="Name" description="{{checkingSkillName}}"
          descriptionType="text" [maxLength]="50">
        </dds-input>
      </div>

      @if(originalSkillData?.skillid){
      <div class="dds-col-4 skill-id">
        <p><strong>Skill ID</strong></p>
        <p>{{ originalSkillData?.skillid }}</p>
      </div>
      }
    </div>

    <dds-textarea class="dds-col" [minHeight]="150" formControlName="skillDescription" id="skillDescription"
      label="Description" labelPosition="internal" placeholder="Enter skill description" [isRequired]="true" [isError]="
            masterSkillForm.controls['skillDescription'].invalid &&
            (masterSkillForm.controls['skillDescription'].dirty ||
              masterSkillForm.controls['skillDescription'].touched)
          " [errorState]="'focusOut'" errorMessage="Is required" ariaLabel="Description">
    </dds-textarea>
    <div class="dds-container">
      <div class="dds-row">
        <div class="dds-col-10 pl-0">
          <dds-input formControlName="skillDescriptionLink" id="skillDescriptionLink" label="Description Link"
            labelPosition="internal" placeholder="Enter skill description link" [isError]="
                  masterSkillForm.controls['skillDescriptionLink'].invalid &&
                  (masterSkillForm.controls['skillDescriptionLink'].dirty ||
                    masterSkillForm.controls['skillDescriptionLink'].touched)
                " [errorState]="'focusOut'" errorMessage="e.g.: http://www.example.com" ariaLabel="Description Link">
          </dds-input>
        </div>
        @if(masterSkillForm.controls['skillDescriptionLink'].value &&
        !masterSkillForm.controls['skillDescriptionLink'].invalid
        && masterSkillForm.controls['skillDescriptionLink'].value.includes('http') ){
        <div class="dds-col-2 pr-0 link-icon-container">
          <a href="{{ masterSkillForm.controls['skillDescriptionLink'].value }}" target="_blank"
            aria-label="click to navigate to description link">
            <span class="link-icon dds-icon dds-icon_external_link__l__stroke"></span>
          </a>
        </div>
        }
      </div>
    </div>

    <dds-select class="dds-col" formControlName="skillCategory" [isRequired]="true" [errorMessage]="'Is required'"
      [isError]="
            masterSkillForm.controls['skillCategory'].invalid &&
            (masterSkillForm.controls['skillCategory'].dirty ||
              masterSkillForm.controls['skillCategory'].touched)
          " [errorState]="'focusOut'" errorMessage="Is required" [list]="categories" [stickerMaxHeight]="250"
      [stickerIsDynamic]="true" label="Select Category" placeholder="Select category" labelPosition="internal"
      (valueChanged)="onCategoryChanges($event)" ariaLabel="Select Category">
    </dds-select>

    <dds-select class="dds-col" formControlName="skillSubCategory" [isRequired]="true" [errorMessage]="'Is required'"
      [isError]="
            masterSkillForm.controls['skillSubCategory'].invalid &&
            (masterSkillForm.controls['skillSubCategory'].dirty ||
              masterSkillForm.controls['skillSubCategory'].touched)
          " [errorState]="'focusOut'" errorMessage="Is required" [list]="filteredSubCategories" [stickerMaxHeight]="250"
      [stickerIsDynamic]="true" label="Select Sub-Category" placeholder="Select sub-category" labelPosition="internal"
      ariaLabel="Select Sub-Category">
    </dds-select>
    <div class="dds-row">
      <dds-select class="dds-col" formControlName="skillType"
        [isError]="(masterSkillForm.controls['skillType'].invalid && (masterSkillForm.controls['skillType'].dirty || masterSkillForm.controls['skillType'].touched))"
        [errorState]="'focusOut'" errorMessage="Is required" [list]="skillTypesList" label="Type"
        placeholder="Select skill type" labelPosition="internal" [stickerMaxHeight]="250" [stickerIsDynamic]="false"
        ariaLabel="Select skill type">
      </dds-select>
    </div>
    <div class="dds-row catalogRow">
      @if(originalSkillData?.skillid){
      <div class="dds-col">
        <p><strong>DST Status</strong></p>
        <p>{{ originalSkillData?.skillstatuslabel }}</p>
      </div>
      }

      @if(originalSkillData?.skillid){
      <div class="dds-col-4">
        <p><strong>Catalog</strong></p>
        <p>{{ originalSkillData?.catalog }}</p>
      </div>
      }
    </div>

    <br>
    <div>
      <app-tag-input #skillTags formControlName="skillTags" [options]="fullTagsCollection"
        label="Skill Tags (Type to search or create)" labelPosition="internal" placeholder=""
        errorMessage="Please enter a valid tag" (newTag)="onNewTag($event)" />
    </div>

    <dds-input [hidden]="!addRequestorFieldVisible" class="dds-col" formControlName="skillRequestor" id="skillRequestor"
      label="Requestor" labelPosition="internal" placeholder="Enter a requestor's email address"
      ariaLabel="Enter a requestor's email address" [maxLength]="77" [isRequired]="addRequestorFieldVisible" [isError]="addRequestorFieldVisible && masterSkillForm.controls['skillRequestor'].invalid &&
            (masterSkillForm.controls['skillRequestor'].dirty || masterSkillForm.controls['skillRequestor'].touched)"
      errorState="focusOut" errorMessage="Enter a valid Deloitte email">
    </dds-input>

    <div class="dds-col-12 footer-buttons">
      <!-- Retire / Reactivate Buttons-->
      @if(this.skillDataId &&!this.skillHasPendingChanges && isApprover){
      @if (this.originalSkillData?.skillstatus === true ) {
      <dds-button theme="dark" kind="primaryLoud" role="button" ariaLabel="Retire Skill" icon="dds-icon_delete__l__stroke"
        (clicked)="onChangeStatusSkill(false)">Retire Skill</dds-button>
      }@else{
      <dds-button theme="green" kind="primary" role="button" ariaLabel="Reactivate Skill" icon="dds-icon_send__l__stroke"
        (clicked)="onChangeStatusSkill(true)">Reactivate Skill</dds-button>
      }
      }

      <!-- Add Requestor Button -->
      @if(addReqiestorBtnVisible){
      <dds-button theme="blue" kind="primary-loud" role="button" ariaLabel="Add requestor" icon="dds-icon_plus__l__stroke"
        (clicked)="onAddRequestorClick()"
        [disabled]="(originalSkillData?.skillstatus === false)||skillHasPendingChanges">Add Requestor</dds-button>
      }

      <!-- Remove Requestor Button -->
      @if (removeRequestorBtnVisible){
      <dds-button theme="dark" kind="primary-loud" role="button" icon="dds-icon_exclude__l__stroke"
        ariaLabel="Remove requestor" (clicked)="onRemoveRequestorClick()"
        [disabled]="(originalSkillData?.skillstatus === false)||skillHasPendingChanges">Remove Requestor</dds-button>
      }
      <!--Request Change button-->
      @if(this.skillDataId){
      <dds-button theme="dark" kind="primary-loud" role="onSubmit" ariaLabel="Request Change"
        icon="dds-icon_send__l__stroke"
        [disabled]="masterSkillForm.invalid || originalSkillData?.skillstatus === false ||skillHasPendingChanges || formHasChanges===false"
        (clicked)="onChangeSkillRequest()">Request
        Change</dds-button>

      }@else{
      <!--Request New Skill button-->
      <dds-button theme="dark" kind="primary-loud" role="onSubmit" ariaLabel="Request New Skill"
        icon="dds-icon_send__l__stroke" [disabled]="skillHasPendingChanges || masterSkillForm.invalid"
        (clicked)="onAddNew()">Request New
        Skill</dds-button>
      }
    </div>
  </form>
  }

</div>
